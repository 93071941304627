import React from "react"
import styled from "styled-components"
import Box from "shared/components/Box"
import { md } from "mill/utils/breakpoints"

// DO NOT ADD MARGIN OR PADDING TO THE SVG AS THIS WILL CAUSE AN INFINITE RE-RENDER
export const Chart = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${md} {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  svg {
    width: 100%;
  }
`

export const Topic = styled(Box)``

export const LegendBlock = styled.div`
  height: 24px;
  width: 24px;
  border: none;
  max-width: 24px;
  background: ${props => props.color};
`

export const TopicContainer = styled.div`
  max-height: 30rem;
  display: grid;
  grid-template-rows: 9rem 1fr;

  @media ${md} {
    max-height: 60rem;
  }

  > div:last-of-type {
    overflow-y: scroll;
  }
`

export const Lines = styled.g`
  path {
    fill: none;
    stroke: ${props => props.theme.colors.primary};
    stroke-width: 4px;
    cursor: pointer;

    &:hover {
      stroke-width: 8px;
    }
  }
`

export const XAxis = styled.g`
  text {
    color: #909090;
    font-size: 1.2rem;
  }

  line,
  path {
    fill: none;
    stroke: none;
  }
`

export const YAxis = styled.g`
  text {
    color: #909090;
    font-size: 1.2rem;
  }
  line {
    stroke: #f0f0f0;
    stroke-width: 2px;
    stroke-dasharray: 4px;
  }

  path.domain {
    display: none;
  }
`
